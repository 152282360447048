<template>
  <div class="join center">
    <div class="baseBox">
      <div class="joinBox findEmail">
        <h2>
          찾으려는 이메일주소는<br />
          <b>{{ email }}</b> 입니다.
        </h2>
        <div class="buttonWrap">
          <router-link to="/login"
            ><button class="point large">로그인</button></router-link
          >
          <router-link to="/findPassword"
            ><button class="large pointOut">비밀번호찾기</button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  mounted() {
    this.email = this.$route.query.accountId;
  },
  methods: {
    getAccountId() {
      this.email = this.$route.query.accountId;
    },
  },
};
</script>
